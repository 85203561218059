/* fonts start */
@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('./assets/fonts/SFProDisplay-UltralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

/* fonts end */
/* input[type="date"] {
    position: relative;
} */

.icon-place input[type="time"]::-webkit-calendar-picker-indicator,
.icon-place input[type="date"]::-webkit-calendar-picker-indicator,
.custom-report-form input[type="time"]::-webkit-calendar-picker-indicator,
.custom-report-form input[type="date"]::-webkit-calendar-picker-indicator {
    filter: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: url(./assets/images/login-flow-images/date_icon.svg) no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    filter: brightness(20.5);
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: url(./assets/images/login-flow-images/time_icon.svg) no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none !important;
}
input[type=time] {
    -webkit-appearance: none !important;
  } */
/* input[type="date"]::after {
    content: "";
    display: block;
    background: url(./assets/images/login-flow-images/date_icon.svg) no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    transform: translateX(-2%);
    z-index: 9;
    right: 14px;
} */
/* input[type="time"] {
    position: relative;
}
input[type="time"]::after {
    content: "";
    display: block;
    background: url(./assets/images/login-flow-images/time_icon.svg) no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    transform: translateX(-2%);
    z-index: 9;
    right: 14px;
} */


body {
    margin: 0 auto;
    padding: 0;
    font-family: 'SF Pro Display';
}

*,
:hover {
    outline: none !important;
}

a:hover {
    color: #f47820;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
}

img {
    max-width: 100%;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

a,
button,
li {
    text-decoration: none !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    letter-spacing: 0;
    list-style: none !important;
}

.opacity {
    opacity: 1 !important;
}

select {
    appearance: none !important;
    -webkit-appearance: none !important;
}

.weight-400 {
    font-weight: 400;
}

p {
    font-weight: 300;
}

p:last-child {
    margin: 0;
}

table {
    width: 100%;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

input::-webkit-input-placeholder {
    /* Edge */
    color: rgb(55, 61, 63);
    font-weight: 300;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(55, 61, 63);
    font-weight: 300;
}

input::placeholder {
    color: rgb(55, 61, 63);
    font-weight: 300;
}

.cursor {
    cursor: pointer !important;
}

.w-300 {
    width: 180px;
}

.mt-100 {
    margin-top: 100px;
}

span.show-input-error {
    margin-top: 0px;
    color: rgb(171, 30, 30);
    font-size: 12px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
    margin-left: 5px;
}

.canvasjs-chart-credit {
    display: none !important;
}

.custom-modal-content-radius div.modal-content {
    border-radius: 15px;
}

.custom-modal-content-width {
    min-width: fit-content !important;
}

.custom-modal-content-vehicle-width {
    min-width: 1000px !important;
}

tr td.no-found {
    text-align: center !important;
}

.custom-vehicle-selected {
    background-color: #FF5050 !important;
    color: #fff !important;
    border-color: #FF5050 !important;
}

.custom-vehicle-selected-second {
    background-color: #551D87 !important;
    color: #fff !important;
    border-color: #551D87 !important;
}

a.theme-text {
    color: #551D87;
    cursor: pointer;
}

/* login screens start */
.login-bg {
    background-image: url("./assets/images/login-flow-images/login-bg2.png");
    background-size: cover;
    background-position: center;
    position: relative;
}

.overlay-login {
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.35);
}

.logo-position {
    position: absolute;
    top: 0px;
    left: 0px;
}

.logo-position i {
    background: url(./assets/images/login-flow-images/guilogobg.svg) no-repeat center;
    padding: 0;
    /* background-size: 100% 280%; */
    background-size: 150% 280%;
    width: 222px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.logo-position i img.logotext {
    position: relative;
    left: -10px;
}

.login-cover {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px;
    width: 510px;
    /* height: 490px; */
}

.flex-c {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-head {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    text-transform: capitalize;
    padding-bottom: 57px;
}


/* form css */
.form-select,
.form-control {
    font-size: 14px;
}

form label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding-bottom: 0px;
}

input.form-control,
select.form-select,
textarea.form-control {
    background-color: #f8f2f8;
    border-radius: 10px;
    height: 45px;
    border: 0px;
    font-weight: 400;
}

textarea.form-control {
    height: auto;
    resize: none;
}

input.form-control:focus,
select.form-select:focus,
textarea.form-control:focus {
    outline: 0px;
    box-shadow: none;
    color: #000 !important;
    background-color: #f8f2f8 !important;
}

select.form-select:focus+.styled-select:after {
    color: #000 !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgb(55, 61, 63) !important;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: rgb(55, 61, 63) !important;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: rgb(55, 61, 63) !important;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #C5CCD5 !important;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
}

.btn-hover {
    width: 100%;
    font-weight: 500;
    color: #fff;
    height: 50px !important;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    box-shadow: none;
}

.btn-hover:hover {
    background-position: 100% 0;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    /* background-image: linear-gradient(to right, #551D87, #551D87, #a630dd, #9d2bb6); */
    color: #fff;
    background: #551D87;
}

.login-btn {
    background: #551D87;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
}

.login-btn:hover {
    color: #fff;
}

.acc-create {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.acc-create a {
    color: #551D87;
    padding-left: 3px;
    text-decoration: underline !important;
}

/* end */

/* checkbox custom */
input[type=checkbox]+label {
    display: block;
    cursor: pointer;
    padding: 0.2em;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox]+label:before {
    content: "";
    border: 0.1em solid #C4C4C4;
    border-radius: 0.2em;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.6em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
}

input[type=checkbox]+label:active:before {
    transform: scale(0);
}

.login-form-check input[type=checkbox]:checked+label::after {
    left: 6px;
    top: 11px;
}

.login-form-check input[type=checkbox]:checked+label:before {
    background-color: #FD9C00;
    border-color: #FD9C00;
    padding-left: 0;
    color: #fff;
}

input[type=checkbox]:disabled+label:before {
    transform: scale(1);
    border-color: #aaa;
}

input[type=checkbox]:checked:disabled+label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}

.forgot-pw {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #FD9C00;
}

.flex-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-text a:hover {
    color: #FD9C00;
}

/* end */
select.form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
}

.styled-select:after {
    position: absolute;
    content: '\f107';
    right: 14px;
    top: 50%;
    font-family: fontAwesome;
    color: #551D87;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.styled-select {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 14px;
    top: 50%;
    color: #5F6A78;
    font-size: 14px;
    transform: translateY(-50%);
}

.veryfy-otp {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    opacity: 0.7;
}

.pb-57 {
    padding-bottom: 57px;
}

.pb-47 {
    padding-bottom: 47px;
}

.edit-image {
    height: 120px;
    width: 120px;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}

.edit-size {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    display: block;
    position: relative;
    border-radius: 50%;
}

.round-shape {
    border-radius: 50%
}

.edit-pos {
    position: absolute;
    right: -3px;
    top: 5px;
}

.verify-acc {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    padding-bottom: 36px;
}

/* login screens end */


/* modals css start */
.edit-modal {
    border-radius: 10px;
}

.edit-modal .modal-body {
    padding: 0px;
}

.header-modal {
    background-color: #551D87;
    border-radius: 10px 10px 0px 0px;
    padding: 23px 30px;
    display: flex;
    justify-content: space-between;
}

.edit-modal .btn-close {
    opacity: 1;
}

.icon-side {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.p-30 {
    padding: 30px;
}

.icon-place {
    position: relative;
}

.icon-place input {
    padding-right: 50px;
}

.icon-pos {
    position: absolute;
    right: 15px;
    top: 12px;
}

.popup-input input,
.popup-input select {
    background: #F8F2F8 !important;
}

input:-webkit-autofille {
    background-color: #F8F2F8;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.popup-input-date .ui.input {
    width: 100%;

}

.popup-input-date .ui.input input {
    background: #F8F2F8;

}

.popup-input-date .ui[class*="left icon"].input>input {
    padding-right: 1.5em !important;
    padding-left: 10px !important;
    border: 0px;
}

.note-bold {
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 10px;
}

.day-list li {
    margin: 5px 10px 0px 0px;
}

/* radio css */
.radio-out2 label.active {
    background-color: #54257F !important;
    z-index: 999;
    color: #fff;
    border-radius: 10px;
}

.radio-out input {
    background: transparent !important;
}

.radio-out2 {
    background: #F8F2F8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
}

.radio-out2 label {
    padding-bottom: 0px;
    margin: 0px;
    height: 50px;
    width: 98.75px;
    display: flex;
    padding: 15px;
    align-items: center;
}

.radio-out2 .form-check {
    margin-bottom: 0px;
}

.radio-out2 .form-check-input {
    width: 21px;
    height: 21px;
    margin-top: 0px;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #54257F;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    margin-right: 8px;
}


.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
    content: '';
    background: #fff;
    /* border: 2px solid #ddd; */
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    background: rebeccapurple;
    color: #fff;
}

.radio-custom+.radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked+.radio-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #54257F;
}

.checkbox-custom:focus+.checkbox-custom-label,
.radio-custom:focus+.radio-custom-label {
    outline: 1px solid #54257F;
    /* focus style */
}

/* radio end */
.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}

/* rder-details popup */
.locatinn-h {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 8px;
}

.locatinn-p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.p-20 {
    padding: 20px;
}

.p-l-30 {
    padding-left: 30px !important;
}

.popup-table .table-dark.table-light th {
    font-weight: 500;
    font-size: 12px;
    border-bottom: 0px;
    background: #EFF4F8;
    text-transform: capitalize;
    color: #000000;
    padding: 12px 15px;
}

.popup-table .table-dark.table-light tr td {
    font-weight: 400;
    border-bottom: 0px;
    background: #EFF4F8;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #000000;
    padding: 12px 15px;
}

.vehicle-charge {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 15px 0px;
}

.popup-table tr td {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #000000;
    padding: 18px 15px;
}

.popup-table tbody>tr:last-child>td {
    border-bottom: 0;
}

.table-height {
    height: 350px;
    overflow-y: auto;
}

.drop-clr.styled-select:after {
    color: #809FB8;
    font-size: 18px;
}

.light-blue input,
.light-blue select {
    background-color: #EFF4F8;
}

.popup-input label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-bottom: 10px;
}

.delet-card {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #000000;
}

.update-btn {
    background-color: transparent;
    border: 1.5px solid #551D87;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #551D87;
    margin-top: 15px;
}

.update-btn:hover {
    color: #551D87;
}

.yes-btn {
    background: #FF4F4F;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 15px;
}

.yes-btn:hover {
    color: #fff;
}

.btn-default {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.clr-purp {
    background-color: #551D87;
}

.thank-size {
    font-size: 32px;
    margin: 20px 0px 5px 0px;
}

.loc-success {
    text-align: center;
    color: #000;
    opacity: .6;
    font-size: 16px;
    font-weight: 500;
}

.btn-lock {
    width: 232px;
    height: 42px;
    background: #551D87;
    border-radius: 4px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin: 0 auto;
    display: flex;
    margin-top: 10px;
}

.styled-select.black-iocn:after {
    color: #595959;
}

.sample-c {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #551D87;
    margin-bottom: 15px !important;
    text-align: right;
}

.icon-input {
    position: relative;
}

.icon-input .form-control {
    padding-right: 105px;
}

.icon-pos2 {
    position: absolute;
    right: 11px;
    top: 15px;
    font-size: 14px;
    width: 91px;
}

/* end */

.adress-vehicle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    padding: 5px 0px;
}

.front-view {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-top: 10px;
}

.text-version p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.6;
    margin-bottom: 5px;
}

.text-version h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding-bottom: 20px;
}

.text-version {
    padding: 0px;
}

.text-version .row {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.red-dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #EC1616;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-color: #F8F2F8 !important;
    border-width: 1px !important;
    border: solid;
}

.border-right-0 {
    border-right: 0px !important;
}

.blue-btn:active,
.blue-btn:focus,
.blue-btn {
    background: transparent;
    border: 0px;
    border-radius: 3px;
    color: #551D87;
    font-size: 16px;
    outline: none !important;
    padding: 10px 10px;
}

.fileUpload {
    text-align: left;
}

.fileUpload input.uploadlogo {
    position: absolute;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 100%;
    height: 42px;
}

/*Chrome fix*/
input::-webkit-file-upload-button {
    cursor: pointer !important;
    height: 42px;
    width: 100%;
}

.image-upload input::-webkit-file-upload-button {
    cursor: pointer !important;
    height: 45px;
    width: 0%;
    font-size: 0;
    border: 0;
    background-color: #fff !important;
}

.image-upload .blue_btn {
    border-radius: 3.67442px;
}

.image-upload .blue_btn:hover,
.image-upload .blue_btn:focus,
.image-upload .blue_btn:active {
    border-color: #551D87;
    background: #551D87;
    color: #fff;
}

.image-upload .form-control[type=file] {
    background-color: #fff;
    border: 1px solid #551D87;
    line-height: 31px;
    border-radius: 3.67442px;
}

.input-design {
    background-color: #EFF4F8;
    border-radius: 10px !important;
    height: 50px;
    border: 0px;
    font-weight: 400;
    width: 100%;
}

.up-file {
    position: absolute;
    width: 113px;
    height: 28px;
    background: #551D87;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    padding: 0px;
}

/* modals css end */

/* place new order css start */
ul li {
    list-style: none;
}

.wrapper {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    background-color: #fff;
    min-height: calc(100vh - 126px);
}

.delivery-schdle {
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: rgb(55, 61, 63);
    padding: 5px 0px;
}

.drop-location select {
    background: #FFFFFF;
    border: 1px solid rgb(55, 61, 63);
    box-sizing: border-box;
    border-radius: 10px;
    color: #552581;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 0px 40px;
}

.loc-pos {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.drop-location select:focus {
    outline: 0px;
    box-shadow: none;
    background-color: transparent !important;
    border: 1px solid #5f2581;
}

.anchor-btn {
    height: 50px;
    background: #EFF4F8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #551D87;
    margin-top: 15px;
    transition: all .7s;
}

.anchor-btn:hover {
    color: #551D87;
}

.unactive-hide {
    display: none;
}

.anchor-active {
    background: #551D87;
}

.anchor-active.anchor-btn {
    /* display: block; */
    color: #fff !important;
}

.anchor-active .unactive-hide {
    display: inline-block;
}

.anchor-active .active-show {
    display: none;
}

.search-bar {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 90%;
    position: absolute;
    top: 30px;
    transform: translate(-50%);
    left: 50%;
}

.search-bar input {
    width: 100%;
    padding: 5px 30px 5px 10px;
    color: #000;
    font-size: 14px;
    border: 0px;
}

.search-bar input::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: rgba(17, 17, 17, 0.4);
}

.confirmation {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #662581;
    margin-bottom: 25px;
}

.delivery-schdle2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #000000;
    opacity: 1;
    padding: 10px 0px;
}

.product-card {
    background: #FFFFFF;
    margin-top: 20px;
    box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.1);
}

.card-content {
    padding: 10px;
}

.card-content h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #000000;
}

.card-content p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    padding: 7px 0px;
    text-transform: capitalize;
    color: #000000;
    opacity: 0.5;
}

.card-content h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #52257E;
    padding-bottom: 5px;
}

.img-height {
    height: 167px;
    overflow: hidden;
}

.img-height img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.border-r {
    border-right: 2px solid rgba(0, 0, 0, .2);
}

.min-h {
    min-height: calc(100vh - 126px);
}

.payment {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
}

.apply-pos {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    text-transform: capitalize;
    color: #551D87;
    position: absolute;
    right: 15px;
    top: 15px;
}

.apply-space {
    padding-right: 70px;
}

input.apply-space::placeholder {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5) !important;
}

.bold-border {
    border: 1px solid rgba(0, 0, 0, .2);
    margin: 25px 0px;
}

.purp-clr select {
    color: #7D2580;
    font-size: 14px;
}

.add-new {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    text-transform: capitalize;
    color: #551D87;
}

.line-head h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
}

.line-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.visa-outer {
    background: #EFF4F8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 15px;
}

.visa-outer p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-left: 15px;
    color: #000000;
}

.visa-outer .checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
    content: '';
    background: #fff;
    /* border: 2px solid #ddd; */
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    line-height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border: 1px solid #999999;
    background: #EFF4F8;
}

.visa-outer label {
    padding-bottom: 0px;
}

.visa-outer .radio-custom:checked+.radio-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #fff;
    background: #551D87;
}

.h-100 {
    height: 100%;
}

.border-round {
    border-radius: 84px !important;
    padding: 10px 22px !important;
}

.map-height {
    height: calc(100vh - 126px);
}

.map-height img,
.map-height iframe {
    width: 100%;
    height: 100%;
}

.location-outer {
    padding-right: 50px;
    display: flex;
}

.location-nav li {
    width: 100%;
    padding: 16px 15px;
    border-bottom: 1px solid rgba(84, 37, 129, 0.1);
    position: relative;
}

.icon-location {
    padding-right: 10px;
}

.text-location {
    padding-left: 12px;
}

.text-location h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #551D87;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

}

.text-location h5 span {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    text-transform: uppercase;
    color: rgba(17, 17, 17, 0.5);
}

.text-location p {
    color: rgba(17, 17, 17, 0.7);

}

.pos-drop {
    position: absolute;
    right: 15px;
    top: 30px;
}

.text-location {
    width: 100%;
}

/* place new order cssend */


/* Chander Css Start From Here */

.content-wrapper {
    position: relative;
    z-index: 1;
}

.content-wrapper:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 315px;
    /* background: linear-gradient(89.97deg, #551D87 14.9%, #7E2681 70.68%, #551D87 99.97%); */
    background-color: #551D87;
    z-index: -1;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #551D87;
}

.payment-data {
    height: 400px;
}

/* .payment-data::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #F0F0F0;
}

.payment-data::-webkit-scrollbar {
    width: 18px;
    background-color: #F0F0F0;
}

.payment-data::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #CDCDCD;
} */

.t-b-arrow:before {
    content: '\f106';
    font-family: "Font Awesome 5 free";
    font-weight: 700;
    width: 18px;
    height: 20px;
    line-height: 20px;
    background: #F8F2F8;
    position: absolute;
    top: 40px;
    z-index: 99;
    right: 0;
    text-align: center;
}

.t-b-arrow:after {
    content: '\f107';
    font-family: "Font Awesome 5 free";
    font-weight: 700;
    width: 18px;
    height: 20px;
    line-height: 20px;
    background: #F8F2F8;
    position: absolute;
    bottom: 38px;
    z-index: 99;
    right: 0;
    text-align: center;
}

body.fixed-nav {
    padding-top: 56px;
}

.content-wrapper {
    min-height: 100vh;
    padding-top: 1rem;
}

.scroll-to-top {
    position: fixed;
    right: 15px;
    bottom: 3px;
    display: none;
    width: 50px;
    height: 50px;
    text-align: center;
    color: white;
    background: rgba(52, 58, 64, 0.5);
    line-height: 45px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
    color: white;
}

.scroll-to-top:hover {
    background: #343a40;
}

.scroll-to-top i {
    font-weight: 800;
}

.smaller {
    font-size: 0.7rem;
}

.o-hidden {
    overflow: hidden !important;
}

.z-0 {
    z-index: 0;
}

.z-1 {
    z-index: 1;
}

#mainNav .navbar-collapse {
    overflow: auto;
    max-height: 75vh;
}

#mainNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    cursor: pointer;
}

#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
    float: right;
    content: '\f107';
    font-family: "Font Awesome 5 free";
    font-weight: 700;

}

#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse.collapsed:after {
    content: '\f105';
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level {
    padding-left: 0;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a {
    display: block;
    padding: 0.5em 0;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a:focus,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a:hover,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a:focus,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a:hover {
    text-decoration: none;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level>li>a {
    padding-left: 1em;
}

#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level>li>a {
    padding-left: 2em;
}

#mainNav .navbar-collapse .sidenav-toggler {
    display: none;
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link {
    position: relative;
    min-width: 45px;
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
    float: right;
    width: auto;
    content: '\f105';
    border: none;
    font-family: 'FontAwesome';
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link .indicator {
    position: absolute;
    top: 5px;
    left: 21px;
    font-size: 10px;
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown.show>.nav-link:after {
    content: '\f107';
}

#mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown .dropdown-menu>.dropdown-item>.dropdown-message {
    overflow: hidden;
    max-width: none;
    text-overflow: ellipsis;
}

i.d-icon {
    width: 24px;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
}

#mainNav.fixed-top .sidenav-toggler {
    display: none;
}

#mainNav.fixed-top.navbar-dark .sidenav-toggler {
    background-color: #212529;
}

#mainNav.fixed-top.navbar-dark .sidenav-toggler a i {
    color: #adb5bd;
}

#mainNav.fixed-top.navbar-light .sidenav-toggler {
    background-color: #dee2e6;
}

#mainNav.fixed-top.navbar-light .sidenav-toggler a i {
    color: rgba(0, 0, 0, 0.5);
}

body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler {
    overflow-x: hidden;
    width: 55px;
}

body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-item,
body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-link {
    width: 55px !important;
}

body.sidenav-toggled #mainNav.fixed-top #sidenavToggler i {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
}

#mainNav.static-top .sidenav-toggler {
    display: none;
}

body.sidenav-toggled #mainNav.static-top #sidenavToggler i {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
}

.bg-black {
    background-color: #000;
}

.content-wrapper {
    overflow-x: hidden;
    background-color: #E5E5E5;
}

#sidenavToggler i {
    font-weight: 800;
}

.navbar-sidenav-tooltip.show {
    display: none;
}

body.sidenav-toggled .navbar-sidenav {
    width: 55px;
}

body.sidenav-toggled .navbar-sidenav .nav-link-text {
    display: none;
}

body.sidenav-toggled .navbar-sidenav .nav-item,
body.sidenav-toggled .navbar-sidenav .nav-link {
    width: 55px !important;
}

body.sidenav-toggled .navbar-sidenav .nav-item:after,
body.sidenav-toggled .navbar-sidenav .nav-link:after {
    display: none;
}

body.sidenav-toggled .navbar-sidenav .nav-item {
    white-space: nowrap;
}

body.sidenav-toggled .navbar-sidenav-tooltip.show {
    display: flex;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
    color: #868e96;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
    color: #551D87;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link:hover {
    color: #551D87;
    background-color: rgba(126, 38, 129, 0.1);
    position: relative;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link::before {
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #551D87 0%, #7E2681 77.08%, #551D87 99.99%); */
    background-color: #551D87;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0;
    visibility: hidden;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link:hover::before {
    opacity: 1;
    visibility: visible;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
    color: #868e96;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:focus,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:hover,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:focus,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:hover {
    color: #adb5bd;
}

#mainNav.navbar-dark .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
    color: #adb5bd;
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
    color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
    color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item>.nav-link:hover {
    color: rgb(55, 61, 63);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
    color: rgba(0, 0, 0, 0.5);
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:focus,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a:hover,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:focus,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a:hover {
    color: rgb(55, 61, 63);
}

#mainNav.navbar-light .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
    color: rgba(0, 0, 0, 0.5);
}



.content-wrapper .container-fluid {
    padding: 0 30px 50px;
}

.round-icon {
    width: 31px;
    height: 31px;
    line-height: 28px;
    background: #fff;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
}

.status-dot {
    border-radius: 50%;
    background: #FF7900;
    border: 2px solid #551D87;
    box-sizing: border-box;
    width: 11px;
    height: 11px;
    position: absolute;
    top: 0;
    right: 0;
}

ul.main-links {
    gap: 20px;
}

.white-btn {
    background: #FFFFFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #551D87;
    padding: 9px 7px;
}

h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    color: #FFFFFF;
}

h1 i img,
.brightness {
    filter: brightness(0) invert(1);
    image-rendering: -webkit-optimize-contrast;
}

ul.main-links>li {
    padding: 0 0 15px;
    display: flex;
}

.notification-list {
    position: absolute;
    top: 130%;
    right: 0;
    width: 320px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.notification-btn:hover .notification-list {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #652581;
}

.notification-list h6,
.notification-list ul {
    padding: 20px 20px 0 16px;
}

.notify-text i.round-icon {
    background: rgb(101 37 129 / 5%);
    position: absolute;
    left: 0;
    top: 4px;
}

ul.notify-text li {
    position: relative;
    padding-left: 45px;
}

ul.notify-text li:last-child {
    margin-bottom: 0 !important;
}

.notify-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.notify-text p:last-child {
    opacity: 0.5;
}

.w-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #551D87;
    border-top: 2px solid #E6E6EC;
    padding: 14px 0;
}

h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #551D87;
}

h3 span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.4);
}

.h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #111111;
}

.admin-info p {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: rgb(55, 61, 63);
}

.card {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.1);
}

.text-16 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000000 !important;
}

.text-12 {
    font-size: 12px;
    line-height: 14px;
    color: rgb(55, 61, 63);
}

.t-color {
    color: #551D87;
}

b {
    font-weight: 600 !important;
}

.automate-top>* {
    width: auto;
    padding: 0;
}

.fa-solid.fa-circle {
    font-size: 4px;
}

.bg-gray {
    background: rgba(126, 38, 129, 0.1) !important;
}

span.t {
    font-size: 9px;
}

span.d {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
}

.total-box h3 {
    color: #000;
}

.total-box p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(55, 61, 63);
}

.duration-text {
    font-weight: 600;
    color: rgb(55, 61, 63);
}

.duration-text span {
    color: rgb(55, 61, 63);
}

p.duration-text span {
    float: right;
}

.duration-time {
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 4px 0px 4px 4px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: rgb(55, 61, 63);
    opacity: 0;
    top: 100%;
    visibility: hidden;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    z-index: 99;
}

.t-menu .duration-time {
    text-align: right;
    min-width: 73px;
    top: 150%;
    right: 5px;
}

.t-menu:hover .duration-time {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.duration-time li {
    border-bottom: 1px solid rgba(84, 37, 129, 0.15);
    padding: 9px 12px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.04em;
}

.duration-time li:hover {
    background: #f2e9f2;
}

.duration-time li:last-child {
    border-bottom: 0;
}

.regular-price .duration-time,
.address-list .duration-time {
    font-size: 12px;
    width: 194px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    opacity: 1;
    visibility: visible;
    display: none;
}

.regular-price .duration-time {
    font-weight: 600;
    color: #551D87;
}

.address-list .duration-time {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
}

.price-btn,
.price-btn:hover,
.price-btn:focus,
.address-list a,
.address-list a:hover,
.address-list a:focus {
    color: #551D87;
}

.t-menu {
    cursor: pointer;
}

.address-list .ellipsis {
    width: 155px;
}

.nav-tabs.custom-tabs .nav-link.active {
    border-color: #551D87;
    color: #551D87;
}

.nav-tabs.custom-tabs .nav-link {
    border: 0;
    border-bottom: 2px solid transparent;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgb(55, 61, 63);
}

.custom-tabs {
    gap: 20px;
}

.download-btn {
    background: rgba(84, 37, 129, 0.1);
    border-radius: 5px;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.download-btn img {
    height: 12px;
}

.common-table thead {
    border-bottom: 1px solid #ddd;
}

table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.common-table th,
.common-table td {
    padding: 12px 16px;
    line-height: 17px;
    font-size: 13px;
    color: #000000;
}

.common-table th {
    font-weight: 500;
    font-size: 14px;
    background-color: #fff;
}

i.fa-solid.fa-eye {
    color: #551D87;
}

.icon-td i img {
    height: 16px;
    image-rendering: -webkit-optimize-contrast;
}

.table-h {
    height: 248px;
}

.bg-th th,
.odd-table thead th,
.odd-table tbody tr:nth-child(odd) {
    background: #F8F2F8;
}

.map-outer .search-bar {
    top: 5px;
}

.switch {

    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C4C4C4;
    box-shadow: none;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: #FFB039;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #FFB039;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

.slider.round {
    border-radius: 40px;
}

.slider.round:before {
    border-radius: 50%;
}

/*  Paginatoin Css */
.page-item:first-child .page-link {
    margin-left: 0;
}

.page-item:last-child .page-link {
    margin-right: 0;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
    width: 36px;
    height: 36px;
    line-height: 34px;
}

.page-link {
    padding: 0;
    margin: 0 8px;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
    font-weight: 400;
    font-size: 18px;
    color: #551D87;
}

.text-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.common-head p.text-16 a {
    color: #FFB039;
}

.type-option .purp-clr select {
    height: 36px;
    border: 1px solid #809FB8;
    box-sizing: border-box;
    border-radius: 4px !important;
    color: #000000;
    background-color: transparent;
}

.text-white {
    color: #fff !important;
}

.wh {
    /* height: calc(100vh - 270px); */
    overflow: auto;
}

.top {
    top: 50%;
}

.transform-y {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.right-0 {
    right: 0;
}

.icon-location {
    margin-top: -4px;
}

ul.nav.location-nav li:hover {
    background-color: #F3EAF3;
}

ul.nav.location-nav {
    height: calc(100vh - 166px);
    overflow-y: auto;
    display: block;
}

ul.nav.location-nav .right-0 {
    right: 10px;
    z-index: 99;
}

.search_location {
    background: rgb(175 172 172 / 25%);
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12); */
    border-radius: 4px;
    width: 527px;
    padding: 24px 20px;
    bottom: 85px;
    left: 0;
    right: 0;
    margin: auto;
}

.search_location input.form-control {
    background: #FFF;
    border-radius: 4px;
    height: auto;
    padding: 9px 10px;
    width: calc(100% - 205px);
}

.search_location button.common-btn {
    width: 188px;
    height: 42px;
    background: #551D87;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 0;
}

.left-icon img {
    height: 23px;
}

.icon-14 img {
    height: 14px;
}

.custom-search input.form-control {
    background: transparent;
    padding: 0;
    height: auto;
    padding: 8px 10px;
}

.search-icon {
    background: transparent;
    padding: 0 10px;
}

.custom-search {
    background: #FFFFFF;
    border-radius: 6px;
    width: 390px;
    max-width: 100%;
}

.filter .purp-clr select {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px !important;
    background: transparent;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    height: auto;
    width: 200px;
    text-align: left;
    padding: 10px 33px 10px 15px;
}

.filter label {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    text-transform: capitalize;
    white-space: pre;
}

.filter .styled-select:after {
    color: #fff;
}

.icon-16 img {
    height: 16px;
}

a.border-btn {
    background: #FFFFFF;
    border: 1px solid #809FB8;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    min-width: 163px;
    padding: 8.5px 0;
    text-align: center;
}

.border-btn.delete_all {
    background-color: #FF5050;
    border-color: #FF5050;
    color: #fff;
}

.gap-25 {
    gap: 25px;
}

.text-20 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

/* Add Your Vehical Css */
.progressbar {
    counter-reset: step;

}

.progressbar li {
    position: relative;
    list-style: none;
    float: left;
    width: 33.33%;
    text-align: center;
    z-index: 1;
    color: #fff;
}

/* Circles */
.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: transparent;
    line-height: 32px;
    background: #551D87;
    border: 5px solid #8B519A;
    color: #fff;
}

.progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #8B519A;
    top: 20px;
    left: -1%;
    z-index: -1;
}

.progressbar li.active:before,
.progressbar li.active:after {
    border-color: #fff;
    background: #ffffff;
    color: #000;
}

/* Radio Button Css */

.radio {
    margin: 0.5rem;
}

.radio label {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.radio input[type=radio] {
    position: absolute;
    opacity: 0;
}

.radio input[type=radio]+.radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    margin-right: 10px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

.radio input[type=radio]:checked+.radio-label:before {
    background-color: #FF941B;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio input[type=radio]:focus+.radio-label:before {
    outline: none;
    border-color: #FF941B;
}

.radio input[type=radio]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.radio input[type=radio]+.radio-label:empty:before {
    margin-right: 0;
}

.buttons a {
    width: 91px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 9.5px 0;
    text-align: center;
}

.buttons a:hover {
    background-color: #fff;
    color: #551D87;
}



.profile-pic {
    height: 113px;
}

.file-upload {
    display: none;
}

.circle {
    width: 100%;
    height: 280px;
    overflow: hidden;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

img {
    max-width: 100%;
    object-fit: cover;
}

.p-image:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.upload-button {
    background: #75BF48;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 9px 20px;
    cursor: pointer;
}

.upload-button:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-color: #75BF48;
    color: #75BF48;
    background: #fff
}

.filter input.form-control[type="date"] {
    min-width: 150px;
}

.filter input.form-control[type="date"]::after {
    filter: brightness(20.5);
}

input.form-control[type="date"] {
    background: transparent;
    height: auto;
    border: 1px solid #fff;
    border-radius: 6px;
    color: #fff;
    min-height: 40px;
}

.ui.calendar .icon-place input.form-control[type="date"] {
    color: #551D87;
}

.ui.calendar .icon-place input.form-control[type="date"]::-webkit-calendar-picker-indicator {
    filter: brightness(20.5);
    cursor: pointer;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
}

input.form-control[type="date"]:focus {
    background-color: transparent !important;
}

.order-btn .common-btn {
    min-width: 188px;
}

.common-btn {
    border: 1px solid #fff;
    padding: 11px 14px;
    color: #fff;
    font-size: 14px;
    line-height: 16.71px;
    border-radius: 6px;
    margin-right: 24px;
    cursor: pointer;
}

.common-btn:last-child {
    margin-right: 0;
}

.common-btn i img {
    height: 16px;
}

.common-btn:hover {
    background-color: #fff;
    color: #7c2681;
}

.common-btn:hover .brightness {
    filter: none;
}

.order-table th,
.order-table td {
    padding: 15px 10px;
}

.simple-btn i img {
    height: 18px;
    image-rendering: -webkit-optimize-contrast;
}

a.simple-btn {
    font-weight: 510;
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000000;
    margin-right: 16px;
}

a.simple-btn:last-child {
    margin-right: 0;
}

.page-link:hover,
.page-link:focus {
    color: #fff;
    background-color: #551D87;
    border-color: #551D87;
    box-shadow: none;
}

.text-22 {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
}

.remove-card i img {
    height: 14px;
    image-rendering: -webkit-optimize-contrast;
}

.remove-card {
    right: 10px;
}

.text-16 span {
    font-weight: 400;
}

.custom-button-tabs {
    gap: 20px;
}

.custom-button-tabs .nav-link {
    border: 1px solid #551D87;
    color: #551D87;
    font-weight: 500;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19px;
    width: 198px;
    padding: 12px 0;
}

.custom-button-tabs .nav-item.show .nav-link,
.custom-button-tabs .nav-link.active,
.custom-button-tabs .nav-link:focus,
.custom-button-tabs .nav-link:hover {
    border-color: #551D87;
    background: #551D87;
    color: #fff;
}

.blue_btn {
    background: #551D87;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    padding: 7.5px 10px;
    min-width: 105px;
    text-align: center;
}

.blue_btn:hover,
.blue_btn:focus,
.blue_btn:active {
    border-color: #551D87;
    background: #fff;
    color: #551D87;
}

.user-profile {
    width: 120px;
}

.user-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
}

.user-circle img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.edit-icon {
    top: 0;
    right: 0;
}

.edit-icon .upload-button {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #551D87 0%, #7E2681 65.56%, #551D87 99.99%);
    width: 25px;
    height: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 0;
}

.edit-icon .upload-button img {
    height: 18px;
    image-rendering: -webkit-optimize-contrast;
}

.user-details .text-22 {
    color: #000000;
}

.user-details .text-16 {
    color: #B3B3B3;
    font-weight: 400;
}

.custom-tab-content .blue_btn {
    padding: 12px 10px;
}

/**/

.payment-table .switch input:checked+.slider {
    background-color: #ffffff;
}

.payment-table {
    border-radius: 12px;
    border: 1px solid #e9e9e9;
}

.inside-tab tr th {
    background: #551d87;
    color: #ffffff;
}

.payment-table .slider {
    background: #ffffff;
}

.payment-table .slider:before {
    background-color: #551d87;
}

.payment-table .ngx-pagination {
    margin-bottom: 0;
}

.payment-paginate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
}

.payment-paginate div {
    font-size: 13px;
}

.payment-table .ngx-pagination li a {
    font-size: 13px;
}

.payment-table tbody tr {
    border-bottom: 1px solid #e9e9e9;
}

/*resposnive*/
@media (min-width:1450px) {
    .container-fluid {
        max-width: 1685px;
    }
}

@media(max-width:1199px) {

    .home-img>img,
    .img-box>img {
        width: 100%;
    }

}


@media (min-width: 992px) {
    .collapse.d-open-collapes:not(.show) {
        display: block;
    }

    #mainNav .navbar-brand {
        width: 250px;
    }

    #mainNav .navbar-collapse {
        overflow: visible;
        max-height: none;
    }

    .site-logo {
        left: 0;
        top: 0;
        width: 215px;
        text-align: center;
        background: #fff;
        padding: 20px 0;
    }

    .progressbar {
        width: calc(100% - 100px);
        margin-top: 6px;
    }

    #mainNav .navbar-collapse .navbar-sidenav {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 105px;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item {
        width: 215px;
        padding: 0;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav {
        background: #fff;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a {
        color: #551D87 !important;
        background-color: rgba(126, 38, 129, 0.1);
        position: relative;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item.active>.nav-link::before {
        opacity: 1;
        visibility: visible;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:focus,
    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:hover {
        color: white;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level,
    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level {
        background: #fff;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
        padding: 12px 15px;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level,
    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level {
        padding-left: 0;
        list-style: none;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li,
    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li {
        width: 215px;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a,
    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
        padding: 1em;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level>li>a {
        padding-left: 2.75em;
    }

    #mainNav .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level>li>a {
        padding-left: 3.75em;
    }

    #mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link {
        min-width: 0;
    }

    #mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown>.nav-link:after {
        width: 24px;
        text-align: center;
    }

    #mainNav .navbar-collapse .navbar-nav>.nav-item.dropdown .dropdown-menu>.dropdown-item>.dropdown-message {
        max-width: 300px;
    }

    body.sidenav-toggled .content-wrapper {
        margin-left: 55px;
    }

    #mainNav.static-top .sidenav-toggler {
        display: flex;
    }

    .content-wrapper {
        margin: 0 0 0 215px;
    }

    #mainNav.fixed-top .navbar-sidenav {
        height: calc(100vh - 105px);
        overflow-y: auto;
    }

    #mainNav.fixed-top .sidenav-toggler {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: calc(100vh - 56px);
    }

    #mainNav.fixed-top .sidenav-toggler>.nav-item {
        width: 250px;
        padding: 0;
    }

    #mainNav.fixed-top .sidenav-toggler>.nav-item>.nav-link {
        padding: 1em;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav {
        background: #fff;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a {
        color: #000 !important;
        background-color: #fff;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:focus,
    #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:hover {
        color: #000;
    }

    #mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-second-level,
    #mainNav.navbar-light .navbar-collapse .navbar-sidenav>.nav-item .sidenav-third-level {
        background: #f8f9fa;
    }

    .refresh-btn {
        top: 0px;
        font-size: 25px;
    }

    address.company-address {
        margin-bottom: 35px;
    }

    .order-filter .type-option .purp-clr select {
        width: 200px;
    }

    .year-filter .type-option .purp-clr select,
    .month-filter .type-option .purp-clr select {
        width: 112px;
    }

    .report-date input.form-control {
        width: calc(100% - 105px);
    }

    .mb-6 {
        margin-bottom: 40px !important;
    }
}


@media(max-width:1366px) {

    .text-16,
    .text-location h5 {
        font-size: 14px !important;
        line-height: normal !important;
    }

    .text-12,
    .text-location h5 span {
        font-size: 10px;
        line-height: normal;
    }

    .location-outer {
        padding-right: 15px;
    }

    .text-14,
    .duration-time li,
    .download-btn,
    .white-btn,
    .anchor-btn {
        font-size: 12px !important;
        line-height: normal;
    }

    .download-btn img,
    .icon-td i img {
        height: 10px;
    }

    .common-table th,
    .common-table td {
        padding: 12px 10px;
        font-size: 12px;
        line-height: normal;
    }

    .nav-tabs.custom-tabs .nav-link {
        font-size: 14px;
    }

    body,
    .blue_btn,
    .drop-location select,
    .btn-default {
        font-size: 14px;
    }

    input.form-control,
    select.form-select,
    textarea.form-control,
    body .multiselect-dropdown .dropdown-btn {
        height: 38px;
        min-height: 38px;
    }

    .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:after {
        top: 40%;
    }

    .custom-button-tabs .nav-link {
        font-size: 14px;
        width: 150px;
    }

    .mapboxgl-marker {
        width: 25px;
        height: 25px;
    }
}

@media(max-width:991.98px) {

    .form-select,
    .form-control {
        font-size: 12px;
    }

    body {
        font-size: 12px;
    }

    .common-table th,
    .common-table td {
        padding: 10px 10px;
        font-size: 8px;
        line-height: normal;
    }

    .common-table td .text-12 {
        font-size: 8px;
        line-height: normal;
    }

    .download-btn,
    .white-btn {
        font-size: 9px !important;
        line-height: normal;
        padding: 5px 10px;
    }

    .h1,
    h1,
    .text-22 {
        font-size: 18px !important;
        line-height: normal !important;
    }

    .text-16 {
        font-size: 12px !important;
        line-height: normal !important;
    }

    .text-14,
    .duration-time li,
    .download-btn,
    .text-location h5 {
        font-size: 10px !important;
        line-height: normal;
    }

    .duration-time li {
        padding: 5px 10px;
        font-size: 10px;
    }

    .mb-6 {
        margin-bottom: 30px !important;
    }

    a.site-logo img {
        height: 50px;
        image-rendering: -webkit-optimize-contrast;
    }

    .fixed-top {
        top: 0;
        position: fixed;
    }

    .top-black-bar {
        height: 40px;
    }

    .navbar-dark .navbar-toggler-icon {
        color: #732581;
        font-size: 30px;
        height: auto;
    }

    .content-wrapper {
        padding-top: 100px;
    }

    .content-wrapper:before {
        top: 82px;
    }

    nav.navbar.navbar-expand-lg.navbar-dark {
        background: #fff;
    }

    .border-right {
        border: none !important;
    }

    #mainNav.navbar-dark .navbar-collapse .navbar-sidenav>.nav-item>.nav-link {
        padding: 8px 10px;
    }

    .admin-btn:after {
        content: none;
    }

    .admin-btn span {
        display: none;
    }

    .sub-menu {
        display: none;
    }

    .navbar-expand-lg .navbar-nav li.nav-item {
        position: relative;
    }

    .sub-menu li {
        padding: 5px 0;
    }

    .sub-menu a {
        color: #000;
    }

    .sub-menu a:hover {
        color: #71b100;
    }

    .dropdown-toggle {
        color: #4A2C71;
        cursor: pointer;
        position: absolute;
        right: 0;
        display: block;
        top: 5px;
        margin: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-webkit-appearance: none;
        background: 0 0;
        border: none;
        z-index: 1;
        outline: none;
        width: 35px;
    }

    .dropdown-toggle:after {
        position: relative;
        content: "\f105";
        display: inline-block;
        font-family: "Font Awesome 5 free";
        font-weight: 600;
        font-size: 20px;
        margin: 0;
        vertical-align: middle;
        border: none;
        height: auto;
        width: auto;
        line-height: initial;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .5s ease-in-out 0s;
        -webkit-transition: all .5s ease-in-out 0s;
        -moz-transition: all .5s ease-in-out 0s;
        border: none;
    }

    .hide-form {
        right: 15px;
        top: 5px;
    }

    .option-outer {
        width: 100%;
    }

    .type-option {
        width: 50%;
    }
}


@media(max-width:767px) {
    .logo-position {
        position: static;
        margin: 20px 0px;
    }

    .flex-c {
        flex-direction: column;
    }

    .text-version .row {
        border-right: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }

    .login-cover {
        width: 100%;
    }

    .map-height img,
    .map-height iframe {
        height: auto;
    }

    .map-height {
        height: auto;
    }

    .custom-search {
        width: 100%;
    }

    .filter {
        width: 47%;
        display: inline;
    }

    .refresh-btn {
        top: 0px;
        font-size: 25px;
    }

    .filter .purp-clr select,
    .option-outer {
        width: 100%;
    }

    .type-option {
        width: 50%;
    }
}


@media(min-width: 576.98px) {
    .modal-900 {
        max-width: 900px;
    }

    #delete_card .modal-dialog {
        max-width: 473px;
    }
}


.report-date input.form-control {
    background-color: #fff;
    border: 1px solid #809FB8;
    box-sizing: border-box;
    border-radius: 6px;
    height: auto;
}








.t-color {
    color: #551D87 !important;
}

.tr-border tr {
    border-bottom: 1px solid #ddd;
}

.tr-border tr:last-child {
    border-bottom: 0;
}

.custom-search-bar {
    background: #FFFFFF;
    border: 1px solid #809FB8;
    border-radius: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.custom-search-bar input {
    width: 100%;
    padding: 5px 30px 5px 10px;
    color: #000;
    font-size: 14px;
    border: 0px;
}

.common-btn.border-black {
    border-color: #809FB8;
    color: #809FB8;
    width: 100%;
    display: block;
}

.common-btn.border-black:hover {
    background-color: #551D87;
    border-color: #551D87;
    color: #fff;
}

.common-btn.border-black:hover img {
    filter: brightness(0) invert(1);
}

.blue-button {
    background: #551D87;
    border: 1px solid #551D87;
    border-radius: 6px;
    display: inline-block;
    width: 188px;
    padding: 7px 0;
    color: #fff;
}

.blue-button:hover {
    background: #fff;
    color: #551D87;
}

.modal-content {
    border-radius: 12px;
    overflow: hidden;
}

.clear-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FF0000;
}

#space_details .modal-dialog {
    width: 1200px;
    max-width: 100%;
}

.dtn-data table {
    width: 2400px;
}

.dtn-data table th,
.dtn-data table td {
    padding: 15px 10px;
}

.custom-breadcrumb {
    font-weight: 500;
}

.custom-breadcrumb a {
    font-size: 14px;
    line-height: 17px;
    color: #ffffffbb;
}

.custom-breadcrumb li.active {
    color: #fff;
}

.custom-breadcrumb.f-color a {
    color: #4A1E73;
}

.custom-breadcrumb.f-color li.active {
    color: #809FB8;
}

.view-details {
    background: #FFFFFF;
    border: 1px solid #4A1E73;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #4A1E73;
}

.view-details:hover {
    background: #4A1E73;
    color: #fff;
}

.list-data th {
    background: #EFF4F8;
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    font-feature-settings: 'case' on;
    color: #000000;
}

.list-data th,
.list-data td {
    padding: 12px 0;
    width: 50%;
}

.list-data td {
    border-right: 1px solid #ddd;
}

.list-data td:last-child {
    border-right: 0;
}

.dtn-header .blue-button {
    padding: 10px;
}

.tab-links li {
    padding: 0 20px 0 0px;
}

.tab-links li:last-child {
    padding-right: 0;
}

.tab-links li a {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #551D87;
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
}

.tab-links li.active a,
.tab-links li:hover a {
    border-color: #551D87;
}

.head-bg thead th {
    background: #EFF4F8;
    font-weight: 510;
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000000;
}

.u-icon img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.t-buttons a {
    margin-right: 5px;
}

.t-buttons a:last-child {
    margin-right: 0;
}

input[type=checkbox]+label {
    position: relative;
}

input[type=checkbox]:checked+label:before {
    background-color: #551D87;
    border-color: #551D87;
    padding-left: 1px;
    color: #fff;
}

input[type=checkbox]:checked+label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.pl-8 {
    padding-left: 8px !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.f-500 {
    font-weight: 500 !important;
}

.bg-none::before {
    display: none;
}

.dtn-price td {
    font-size: 13px;
}

.dtn-price th,
.dtn-price td {
    padding: 15px 12px;
}

.dtn-price th:first-child,
.dtn-price td:first-child {
    padding-left: 16px;
}

.dtn-price .radio input[type=radio]+.radio-label:before {
    width: 18px;
    height: 18px;
}

.dtn-price th,
.dtn-price td,
.dtn-price .radio label {
    font-size: 13px;
}

.dtn-price input.form-control {
    width: 98px;
    font-size: 13px;
    height: 40px;
}

.delete-button {
    background-color: transparent;
    border: 1px solid #FF3A31;
    color: #FF3A31;
    border-radius: 6px;
    display: inline-block;
    width: 188px;
    padding: 7px 0;
}

.delete-button.active,
.delete-button:hover {
    background: #FF3A31;
    color: #fff;
}

.fw-600 {
    font-weight: 600 !important;
}

.text-black {
    color: #000 !important;
}

.price-input input.form-control {
    padding-right: 100px;
}

.price-input .input-label {
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
}

.h-50 {
    height: 50px !important;
}

.gallons-text span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
}

.gallons-text b {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #792681;
}

.f-14 {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.f-20 {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #000000 !important;
}

.f-18 {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 20px !important;
    color: #000000 !important;
}

.f-35 {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #000000;
}

img.object-none {
    object-fit: unset;
}

.f-16 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
}

.v-id.f-16 {
    font-weight: 500 !important;
    opacity: 1;
}

.graph-outer {
    width: calc(100% - 24px);
    margin-left: auto;
}

.rotate-text {
    transform: rotate(270deg) translateY(-50%);
    position: absolute;
    left: 0;
    top: 50%;
    margin: 0;
}

.cost-content {
    padding-left: 90px;
}

.cost-content .rounded-circle {
    width: 69px;
    height: 69px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.bl-g {
    background-color: rgb(59 138 217 / 40%) !important;
}

.mst-g {
    background-color: rgb(255 176 57 / 40%) !important;
}

.order-date input.form-control[type="date"]:focus {
    background: transparent !important;
    color: #fff !important;
}

.f-22 {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
}

.black-text {
    color: #000 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.notifications-card ul.notify-text li {
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: 10px;
    padding: 20px 20px 20px 55px;
}

.notifications-card ul.notify-text li i.round-icon {
    left: 10px;
    top: 20px;
}

.clear-btn {
    color: #551D87;
}


.mapboxgl-map.mapboxgl-canvas-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.mapboxgl-marker {
    background-image: url('./assets/images/login-flow-images/location-marker-icon.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

#deliveryLocationcontainer .mapboxgl-ctrl-geocoder {
    min-width: 570px;
}

.mapboxgl-ctrl-geocoder {
    min-width: 250px;
}

.mapboxgl-marker svg {
    display: none !important;
}

.satelite-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
}

.m-t-l-b-0 {
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
}

.filter select.form-control {
    background: transparent;
    border-color: #fff;
    color: #fff;
}

:root {
    --color-purple: #551D87 !important;
    --color-lightblue: rgba(128, 159, 184, 1) !important;
}

.bd-purple {
    color: var(--color-purple) !important;
}

.bd-lightblue {
    color: var(--color-lightblue) !important;
}

body .custom-report-form input.form-control[type="date"] {
    background: transparent !important;
    height: 41px;
    border: 1px solid var(--color-purple);
    border-radius: 6px;
    color: var(--color-purple);
}

.custom-report-select.styled-select select {
    border: 1px solid var(--color-purple);
    box-sizing: border-box;
    border-radius: 6px !important;
    background: transparent;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: var(--color-purple);
    height: auto;
    width: 200px;
    text-align: left;
    padding: 10px 15px;
}

.custom-report-form .radio input[type=radio]:checked+.radio-label:before {
    background-color: var(--color-purple);
    border-color: var(--color-purple);
}

.custom-report-form .common-btn {
    background-color: var(--color-purple);
}

.track-order-profile {
    width: 60px;
}

.track-order-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}

.track-order-circle img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.team-member-delete a {
    padding-top: 25px !important;
    position: absolute;
}

.duration-text-center {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.4);
    text-align: center !important;
}

.duration-text-center span {
    color: rgb(55, 61, 63);
}

body.body-container {
    background-color: #000 !important;
    font-family: "Avenir LT Std" !important;
}

td.min-w-120 {
    min-width: 120px !important;
}

td.min-w-100 {
    min-width: 100px !important;
}

.filter .purp-clr select option {
    color: #000 !important
}

.filter .purp-clr select:focus {
    background-color: transparent !important;
    color: #fff !important;
}

.custom-report-form .multiselect-dropdown .dropdown-btn {
    font-size: 14px;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    background-color: transparent !important;
    color: #000 !important;
    border-color: transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
    height: 30px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    width: 26px !important;
    height: 48px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
    box-shadow: none !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before {
    display: none !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:after {
    position: absolute;
    content: '\f107';
    right: 14px;
    top: 45%;
    font-family: fontAwesome;
    color: #551D87;
    transform: translateY(-45%);
}

.multiselecttags .multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #337ab7 !important;
    background: #337ab7 !important;
    color: #fff !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    top: 50% !important;
    border-width: 6px 6px 0 !important;
    transform: translateY(-50%) !important;
}

body .multiselect-dropdown .dropdown-btn {
    font-size: 14px !important;
    min-height: 40px;
    line-height: 2 !important;

}

body .multiselect-dropdown {
    background-color: transparent !important;
}

.pac-container.pac-logo {
    z-index: 9999;
}

.ngx-pagination .current {
    background: #551D87 !important;
    border-radius: 50%;
    display: inline-flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.ngx-pagination .disabled {
    color: rgb(55, 61, 63) !important;
}

#corporate input.form-control,
#corporate select.form-select,
#corporate textarea.form-control,
#profile input.form-control,
#profile select.form-select,
#profile textarea.form-control,
#members input.form-control,
#members select.form-select,
#members textarea.form-control {
    background-color: #f8f8f8;
}

body #profile .multiselect-dropdown,
body #members .multiselect-dropdown,
body #corporate .multiselect-dropdown {
    background-color: #f8f8f8 !important;
    border: 0;
}

body #corporate .multiselect-dropdown .dropdown-btn,
body #profile .multiselect-dropdown .dropdown-btn,
body #members .multiselect-dropdown .dropdown-btn {
    border: 0 !important;
}

.swal2-title {
    font-size: 1.5rem !important;
}